<template>
  <div>
    <Top title="意见反馈" />
    <div class="main-box pt44 feedback">
      <div class="report border-radius">
        <a-form-model
          layout="vertical"
          ref="reportForm"
          :model="form"
          :rules="rules"
        >
          <a-form-model-item ref="advice" class="form-item" prop="advice">
            <label class="iconfont icon-hps-appjianyijiqiwang"
              ><span>请选择反馈类型</span></label
            >
            <a-radio-group
              v-model="form.type"
              @change="handleChangeTag"
              button-style="solid"
            >
              <a-radio-button value="事项Bug"> 事项Bug </a-radio-button>
              <a-radio-button value="需求建议"> 需求建议 </a-radio-button>
              <a-radio-button value="使用体验"> 使用体验 </a-radio-button>
              <a-radio-button value="其他"> 其他 </a-radio-button>
            </a-radio-group>
            <a-input
              v-model="form.advice"
              type="textarea"
              @change="expectInput"
              :maxLength="200"
              @blur="
                () => {
                  $refs.advice.onFieldBlur();
                }
              "
              placeholder="请描述您遇到的问题，不少于10个字"
            />
            <span class="right-tip">{{ expectLength }}/200</span>
          </a-form-model-item>
          <a-form-model-item class="form-item pd">
            <label class="iconfont icon-hps-apptupian"
              ><span>上传图片</span></label
            >
            <!-- <div class="upload-img">
            <a-button type="dashed" size="large"
              ><a-icon type="plus" class="icon-color"
            /></a-button>
          </div> -->
            <div class="upload-img">
              <a-upload
                :action="VUE_APP_API_UPLOAD_URL + '/file/uploadFile'"
                list-type="picture-card"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
                accept="image/*"
                capture="camera"
                :remove="removeFile"
                :headers="token"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" class="icon-color" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="handle-btn border-top">
        <a-button class="save" @click="$router.go(-1)">取消</a-button>
        <a-button class="submit" @click="submitForm">提交</a-button>
      </div>
    </div>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
import Top from "@/components/top/top";
import { FormModel, Input, Button, Icon, Upload, Radio } from "ant-design-vue";
import { recommendation, getWaitList } from "@/utils/api";
export default {
  components: {
    AFormModel: FormModel,
    AFormModelItem: FormModel.Item,
    AButton: Button,
    AInput: Input,
    AIcon: Icon,
    AUpload: Upload,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    Top,
  },
  mounted() {
    let token = localStorage.getItem("accessToken") || "";
    this.token = {
      Authorization: token,
    };
    this.getWaitList();
  },
  created() {},
  data() {
    return {
      VUE_APP_API_UPLOAD_URL: process.env.VUE_APP_API_UPLOAD_URL,
      token: {},
      orderId: this.$route.query.orderId,
      expectLength: 0,
      isLt2M: false,
      form: {
        advice: "",
        pictureName: "",
        pictureUrl: "",
        type: "",
      },
      previewVisible: false,
      previewImage: "",
      fileList: [],
      pendingList: [],
      rules: {
        advice: [
          {
            min: 10,
            max: 200,
            message: "请输入处理方法和处理说明，不少于10个字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  methods: {
    //获取待处理列表
    async getWaitList() {
      const { data } = await getWaitList({ page: 1, limit: 2 });
      this.pendingList = data;
      // this.pendingList = [];
    },
    removeFile(file) {
      this.fileList = [];
      this.form.pictureUrl = "";
      this.form.pictureName = "";
      this.uploading = false;
    },
    //保存
    async saveOrder() {
      const { resp_code, resp_msg } = await recommendation({
        des: this.form.advice,
        type: this.form.type,
        pictureName: this.form.pictureName,
        pictureUrl: this.form.pictureUrl,
      });
      if (resp_code === 0) {
        if (resp_msg) {
          this.$message.success(resp_msg, 3);
        }
        // this.$router.go(-1);
      } else {
        this.$message.error(resp_msg, 3);
      }
      // this.$message.success("保存成功", 3);
    },
    submitForm() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          // this.visible = true;
          this.saveOrder();
        } else {
          console.log(valid);
        }
      });
    },
    //预期文字长度
    expectInput() {
      let length = this.form.advice.length;
      if (length > 200) {
        this.expectLength = 200;
        return;
      }
      this.expectLength = length;
    },
    //隐藏上传图片
    handleCancel() {
      this.previewVisible = false;
    },
    //上传图片预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      this.isLt2M = isLt2M;
      if (!isLt2M) {
        this.$message.error("请选择小于10M的图片!");
      }
      return isLt2M;
    },
    //上传图片
    handleChange(info) {
      //info.file.response
      let response = info.file.response;
      if (response && response.message == "success") {
        let data = response.data;
        this.form.pictureName = data.fileName;
        this.form.pictureUrl = process.env.VUE_APP_API_BASE_URL + data.url;
      }
      if (this.isLt2M) {
        this.fileList = info.fileList;
      }
    },
    handleChangeTag(checked) {
      console.log(checked);
    },
  },
};
</script>
<style lang="less" scoped>
.report {
  position: relative;
  top: 8px;
  background: #fff;
  padding: 18.5px 3.99% 0 3.99%;
  .iconfont {
    font-size: 1.6rem;
    span {
      font-size: 1.4rem;
      margin-left: 0.5rem;
    }
  }
  .form-item {
    margin-bottom: 1.8rem !important;
    .ant-input {
      margin-top: 1.5rem;
    }
  }

  .form-select {
    // width: 50%;
    text-align: right;
    border: 0;
    position: absolute;
    right: 0;
    outline: 0;
    background: none;
  }
  .icon-color {
    color: #909399 !important;
    font-size: 1.8rem;
  }
  .upload-img {
    margin-top: 1.5rem;
    .ant-btn-lg,
    .ant-upload.ant-upload-select-picture-card {
      width: 6rem !important;
      height: 6rem !important;
    }
  }
  .right-tip {
    color: #bdbdbd;
    font-size: 1.2rem;
    position: absolute;
    right: 8px;
    bottom: 8px;
  }
}
</style>